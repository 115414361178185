import React, { useState } from 'react'
import play from '../../assets/images/Play-Pause.png'
import pause from '../../assets/images/Play-Pause1.png'
import cancel from '../../assets/images/Cancel.png'
import save from '../../assets/images/Save.png'
export default function Record_main({ setAddDetails, setRecordsub, recordsub, playRecording, pauseRecording, audioBlob }) {
    const [isPlaying, setIsPlaying] = useState(false);
    const handleClick = () => {
        setIsPlaying(!isPlaying);
      };
      const handlePlayer = () => {
        console.log(audioBlob);
        if(audioBlob==null){
            setRecordsub()
        }
    }  
    return (
        <>
            <div className='div1 play_div'>
                <img src={cancel} alt="no cancel button" onClick={(setRecordsub)} />
            </div>
            <div className='div2 play_div' onClick={handleClick}>
                {isPlaying ? <img src={pause} onClick={() => pauseRecording()} alt="no pause button"/> :  <img src={play} onClick={() => playRecording()}y alt="no play button"/>}
            </div>
            <div className='div3 play_div'>
                <img src={save} alt="no save button" onClick={setAddDetails} />
            </div>
        </>
    )
}
