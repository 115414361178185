import AWS from 'aws-sdk'

export default function PreSignedPrivateUrl(url){
    
    var url = new URL(url)
    let string = url.pathname
    if (string.charAt(0) == "/") string = string.substr(1);

      AWS.config.update({
        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
        region: process.env.REACT_APP_S3_REGION,
      });

      const s3 = new AWS.S3();
    
      const params = {
        Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
        Key: string,
        Expires: 3600,
      };
      
      const presignedUrl = s3.getSignedUrl('getObject', params);
      return presignedUrl
}