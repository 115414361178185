import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import ApiInstance from '../../services/interceptor';
import Header from '../header/header';

export default function CheckNullStatus() {

    const search = useLocation().search;
    let input_biograph_id = (search.slice(13))
    input_biograph_id = decodeURIComponent(input_biograph_id)

    useEffect(() => {
        ApiInstance.get(`v1/admin/check-null-status/?biograph_id=${input_biograph_id}`).then((res) => {
          if (res.status === 200){
            console.log(res.data.responseData.url); //ORIGINAL URL TO REDIRECT

            // REMOVE THIS ONCE UPDATED
            // let url = (res.data.responseData.url).slice(29)
            // console.log(url);

            // window.location.replace(`http://localhost:3000/${url}`)
            // window.location.replace(`http://localhost:3000/create_memory/?biograph_id=${input_biograph_id}`);

            window.location.replace(res.data.responseData.url)
          }
        })
    },[])

    return (
        <>
        <Header />
        </>
    )
}
