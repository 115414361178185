import React, { useEffect, useState } from 'react'
import UpperImage from '../../assets/images/addOtherUser.png'
import Create from '../Create/index'
import Record from '../Record/index'
import AddDetails from '../AddDetails/index'
import Share from '../Share/index'
import story1 from '../../assets/images/listcamera.png'
import story2 from '../../assets/images/Teal-symbol-small.png'
import story3 from '../../assets/images/photo3.png'
import story4 from '../../assets/images/uploadlogo.png'
import Download from '../Download/index'
import Header from '../../components/header/header'
import './index.css'
import {Helmet} from "react-helmet";
import { useLocation } from 'react-router-dom'
import ApiInstance from '../../services/interceptor'
import photoHeader from '../../assets/images/1 Photo.png'
import storyHeader from '../../assets/images/2 Story.png'
import detailHeader from '../../assets/images/3 Details.png'
import shareHeader from '../../assets/images/4 Share.png'


export default function ActivateQR() {
  const [create, setCreate] = useState(true)
  const [record, setRecord] = useState(false);
  const [addDetails, setAddDetails] = useState(false);
  const [share, setShare] = useState(false);
  const [download,setDownload] = useState(false);
  const [playpause,setPlaypause]= useState(false);

  const [loaderState, setLoaderState] = useState(false)

  
  const [recording, setRecording] = useState(false);
  const [audioURL, setAudioURL] = useState(null);
  const [audioBlob, setAudioBlob] = useState(null);
  const [audioContext, setAudioContext] = useState(null);
  const [audioRecorder, setAudioRecorder] = useState(null);

  const [memoryImage, setMemoryImage] = useState({ preview: "", raw: "" })
  const [memoryImageHeight, setMemoryImageHeight] = useState("")
  const [memoryImageWidth, setMemoryImageWidth] = useState("")
  const [memoryAudio, setMemoryAudio] = useState(null)

  const [memoryTitle, setMemoryTitle] = useState("")
  const [memoryPhone, setMemoryPhone] = useState("")
  const [memoryAuthorName, setMemoryAuthorName] = useState("")

  const [errMemoryTitle, setErrMemoryTitle] = useState("")
  const [errMemoryPhone, setErrMemoryPhone] = useState("")
  const [errMemoryAuthorName, setErrMemoryAuthorName] = useState("")

  const [shareURL, setShareURL] = useState("")
  const [popup, setPopup] = useState(false);

  const [browserType, setBrowserType] = useState(false)
  // const [audioBlob, setAudioBlob] = useState(null);
  
  const search = useLocation().search;
  let input_biograph_id = (search.slice(13))
  input_biograph_id = decodeURIComponent(input_biograph_id)

  const openRecord = () => {
    setCreate(false);
    setRecord(true);
  }

  const openAddDetails = () => {
    setRecord(false);
    setAddDetails(true);
  }

  const openShare = () => {
    setAddDetails(false);
    setShare(true);
  }
  const closeAddDetails = () => {
    setMemoryAuthorName("")
    setMemoryPhone("")
    setMemoryTitle("")
    setRecord(true);
    setAddDetails(false);
    setPlaypause(true);
  }
  const closeShare=()=>{
    setShare(false);
    setDownload(true);
  }

  const validation = ()=>{
    const phoneNumberRegex = /^\d{10}$/;

    let validate = true;
    if(memoryTitle==""){
        setErrMemoryTitle("Title is required")
        validate = false
    }
    if(memoryPhone==""){
        setErrMemoryPhone("Phone is required")
        validate = false
    }else if(!phoneNumberRegex.test(memoryPhone)){
      setErrMemoryPhone('Please enter a valid phone number');
        validate = false
    }
    if(memoryAuthorName==""){
      setErrMemoryAuthorName("Author name is required")
      validate = false
    }
    // if(captchaSolved==false){
    //     setErrCaptchaSolved('Please fill captcha');
    //     validate = false
    // }
    return validate;
  }


  useEffect(() => {
    var sBrowser, sUsrAg = navigator.userAgent
        
    if (sUsrAg.indexOf("Firefox") > -1) {
        sBrowser = "Mozilla Firefox";

      } else if (sUsrAg.indexOf("Edge") > -1) {
        sBrowser = "Microsoft Edge";

      } else if (sUsrAg.indexOf("Chrome") > -1) {
        sBrowser = "Google Chrome or Chromium";

      } else if (sUsrAg.indexOf("Safari") > -1) {
        sBrowser = "Safari";

      } else {
        sBrowser = "unknown";
      }

    setBrowserType(sBrowser)
},[])





  const createMemory = () => {

    // setLoaderState(true)

    if(validation()){
      setLoaderState(true)

      let memoryRecordedTime = 0

      let audioFile = ""
      if (audioBlob){
        const file = new File([audioBlob], 'recorded-audio.mp3', { type: 'audio/mpeg' });
        audioFile = file
        memoryRecordedTime = localStorage.getItem("memoryRecordedTime")
      }
      let formData = new FormData();
      formData.append('photo_url',memoryImage.raw)
      formData.append('title',memoryTitle)
      formData.append('name',memoryAuthorName)
      formData.append('phone',memoryPhone)
      formData.append('record_time',memoryRecordedTime)
      formData.append('record_url',audioFile)
      formData.append('browser_type',browserType)
      formData.append('photo_width',memoryImageWidth)
      formData.append('photo_height',memoryImageHeight)

      // setPopup(true);
      
      ApiInstance.patch(`v1/user/activate-null-biographs/?biograph_id=${input_biograph_id}`, formData).then((res) => {
        console.log(res.status,'--------->res status');
        if (res.status === 200){
            setShareURL(res.data.responseData.url)
            setLoaderState(false)
            setPopup(true);
        }
      })
    }
  }

  var imageSrc=""

  if(create){
    imageSrc = photoHeader
  }else if(record){
    imageSrc = storyHeader
  }else if(addDetails){
    imageSrc = detailHeader
  }else if(share){
    imageSrc = shareHeader
  }

  return (
    <section>
      <Header />
      <Helmet>
        <title>Create Memory</title>
        <meta name="description" content="Create Memory page" />
      </Helmet>
      <div className='bio_container'>
        <div className='main_upper'>
          {/* <ul>
            <li>
              <div className='color'>
                <img src={story1} alt="no camera logo" />
              </div>
              <p>1 Photo</p>
            </li>
            <li>
                <div className='color'>
                  <img src={story2} alt="no mic logo" />
                </div>
                <p>2 Story</p>
            </li>
            <li>
              <div className='color'>
                <img src={story3} alt="no check logo" />
              </div>
              <p>3 Details</p>
            </li>
            <li>
              <div className='color'>
                <img src={story4} alt="no upload logo" />
              </div>
              <p>4 Share</p>
            </li>

          </ul> */}
        

          <img width={280} style={{marginLeft:"10%"}} src={imageSrc}/>
          {/* {console.log(create, record, addDetails, share)} */}

        </div>
        <div className='flex' style={{marginLeft:"17%", marginTop:"1%"}}>
          <span style={{fontWeight:"600"}}>1 Photo</span>
          <span style={{marginLeft:"12%", fontWeight:"600"}}>2 Story</span>
          <span style={{marginLeft:"12%", fontWeight:"600"}}>3 Details</span>
          <span style={{marginLeft:"10%", fontWeight:"600"}}>4 Share</span>
        </div>
        <div className='main_lower'>

          {create && <Create setRecord={openRecord} setMemoryImage={setMemoryImage} setMemoryImageHeight={setMemoryImageHeight} setMemoryImageWidth={setMemoryImageWidth} />}
          {record && <Record setAddDetails={openAddDetails} playpause={playpause} setMemoryAudio={setMemoryAudio} audioBlob={audioBlob} setAudioBlob={setAudioBlob}/>}
          {addDetails && <AddDetails closeAddDetails={closeAddDetails} setShare={openShare} setMemoryAuthorName={setMemoryAuthorName} setPopup={setPopup} popup={popup}
                          setMemoryPhone={setMemoryPhone} setMemoryTitle={setMemoryTitle} createMemory={createMemory} errMemoryTitle={errMemoryTitle}
                          setErrMemoryTitle={setErrMemoryTitle} errMemoryPhone={errMemoryPhone} setErrMemoryPhone={setErrMemoryPhone} loaderState={loaderState}
                          errMemoryAuthorName={errMemoryAuthorName} setErrMemoryAuthorName={setErrMemoryAuthorName} setLoaderState={setLoaderState}/>}
          {share && <Share setDownload={closeShare} shareURL={shareURL}  />}
          {download && <Download/>}

        </div>
      </div>
    </section>
  )
}
