import axios from "axios";
// require("dotenv").config();

const ApiInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
  });
  
  ApiInstance.interceptors.request.use(
    (request) => {
      
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: "Basic YWRtaW46YWRtaW4=",
      };

      request.headers = headers;
      return request;
    },
    (error) => Promise.reject(error)
  );


  ApiInstance.interceptors.response.use(
    (response) => {
  
      return response;
    },(error) => {
        console.log('response error--------------->>',error)  

        // if(error.response.status===500){
        //   window.location.replace("https://app.biographbook.com/404");
        // }
        
        // if(error.response.data.error.status_code===404){
        //     window.location.replace("https://app.biographbook.com/404");
        // }

    })

export default ApiInstance;