import React, { useEffect, useState } from 'react'
import cancel from '../../assets/images/Cancel.png'
import save from '../../assets/images/Save.png'
import '../lower.css'
import Popup from "../Popup/index"
import PlayerLoader from '../loader/loader'

export default function AddDetails({ closeAddDetails, setShare, setMemoryTitle, setMemoryAuthorName, setMemoryPhone , createMemory, setPopup, popup, openRecord,
    errMemoryTitle,setErrMemoryTitle,errMemoryPhone ,setErrMemoryPhone,errMemoryAuthorName,setErrMemoryAuthorName, loaderState, setLoaderState}) {
    document.body.classList.remove('record_body');
    document.body.classList.add('add_detail_body');

    useEffect(() => {
        popup && setTimeout(() => {
            setPopup(false);
            setShare();
        }, 1500)
    }, [popup]);

    // const onChange = (value) => {
    //     setCaptchaSolved(true)
    //     setErrCaptchaSolved("")
    // }

    return (
        <>  {!popup ? <><div className='create_div'>
            <div className='main_create_div add_detail_div'>
                <h2>Add details</h2>
                <input type="text" className='add_detail_input' onChange={(e)=>(setMemoryTitle((e.target.value).trim()), setErrMemoryTitle(""))} placeholder='title your memory'/>
                <div className='error'>{errMemoryTitle?errMemoryTitle:""}</div>
                <input type="text" className='add_detail_input' onChange={(e)=>(setMemoryAuthorName((e.target.value).trim()), setErrMemoryAuthorName(""))} placeholder='your name'/>
                <div className='error'>{errMemoryAuthorName?errMemoryAuthorName:""}</div>
                <input type="text" className='add_detail_input' onChange={(e)=>(setMemoryPhone((e.target.value).trim()), setErrMemoryPhone(""))} placeholder='phone number'/>
                <div className='error'>{errMemoryPhone?errMemoryPhone:""}</div>


                {/* <div className='input_div'>
                <ReCAPTCHA
                    sitekey="6LfprPolAAAAAOLA338-v6Uioz90VUqvnTgiY0kr"
                    onChange={onChange}
                />
                <div className='error'>{errcaptchaSolved?errcaptchaSolved:""}</div>
                </div> */}

            </div>
            <div className='create_div_lower add_detail'>

                { loaderState ? <PlayerLoader /> : <>
                <div>
                    <img src={cancel} onClick={() => closeAddDetails()} alt="" />
                </div>
                <div>
                    <img src={save} onClick={() => (createMemory())} alt="" />
                </div>
                </>
                }


            </div>
        </div></>
            : <Popup />
        }
        </>

    )
}
