import '../header/header.css'
import logo from '../../assets/Logo.png'
import downloadBtn from "../../assets/Download App.png"

export default function Header() {


    return (
        <>
            <div className="header">
                <div className="logoImg">
                    <a href='https://www.biographbook.com/'>
                        <img src={logo} height={100} width={100} alt="logo"/>
                    </a>
                </div>

                <div className="menu">
                    <ul className="menuBar">
                       
                            <li className='menuBar_li'>
                                <a className="menu_text" href='https://www.biographbook.com/'>App</a>
                            </li>
                            <li className='menuBar_li'>
                                <a className="menu_text" href='https://app.biographbook.com/books'>Books</a>
                            </li>
                            <li className='menuBar_li'>
                                <a className="menu_text" href='https://www.biographbook.com/journal/'>Our Journal </a>
                            </li>
                            <li className='menuBar_li'>
                                <a className="menu_text" href='https://app.biographbook.com/about'> About </a>
                            </li></ul>
                            <button className="DownloadBTN menu_text menuBar_li" onClick={() => {window.location.replace("https://apps.apple.com/us/app/biograph-app/id1571488188")}}>
                                <img src={downloadBtn} height={"70px"} alt="" />
                            </button>
                    
                </div>
            </div>

        </>
    )
}