import React from 'react'
import '../addMic/addmic.css'
import stop from "../../assets/Stop.png"
import Grouptwo from "../../assets/Grouptwo.svg"
export default function Addmic({recodmic, startRecording}) {
   
    return (

        <div className='add_main' >
            <div className='add_audio' onClick={()=>startRecording()}>
                <p className='add_audio_content'>Add audio <div>message!</div></p>
                <p className='add_audio_content'>--></p>
               
                <img src={stop} 
                alt="background-mic" 
                onClick={recodmic}
                className="stop_image"
                />

            </div>
        </div>
    )
}
