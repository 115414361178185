import React from 'react';
import ReactLoading from 'react-loading';
 
const PlayerLoader = ({ type, color }) => (
    <div>

        <ReactLoading  type={'spin'} color="rgb(50, 181, 241)" height={30} width={30} />
    </div>
);
 
export default PlayerLoader;