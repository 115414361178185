import React from 'react'
import photo from '../../assets/images/addOtherUser.png'
import copylink from '../../assets/images/Copy-Link.png'
import share from '../../assets/images/Share To.png'
import message from '../../assets/images/Messages.png'
import email from '../../assets/images/Email.png'
import facebook from '../../assets/images/Facebook.png'
import twitter from '../../assets/images/Twitter.png'
import '../lower.css'
import {FacebookShareButton, TwitterShareButton} from 'react-share'
import { shareOnMobile } from "react-mobile-share";


export default function Share({setDownload, shareURL}) {
    document.body.classList.remove('add_detail_body');
    document.body.classList.add('share_body');

    const copyToClipboard = () => {
        navigator.clipboard.writeText(shareURL)
        var popup = document.getElementById("myPopup");
        popup.classList.toggle("show");
    }

    const shareOnEmail = () => {
        window.location = `mailto:?subject=Biograph Webrecord&body=Hey Check out this Biograph Memory ${shareURL}`
    }

    const shareOnMessage = () => {
        window.location = `sms:&body=Hey Check out this Biograph Memory ${shareURL}`
    }


    return (
        <div className='create_div share_main_div'>
            <div className='main_create_div'>
                <h2>Share Memory</h2> 
            </div>
            <div className='create_div_lower share_div'>
                <div>
                    <div className='popup1'>
                        <span class="popuptext" id="myPopup">Link Copied!</span>
                    </div>
                    <img src={copylink} style={{'cursor':'pointer'}} alt="no copylink logo" onClick={copyToClipboard}/>
                    <p>Copy link</p>
                </div>
                <div className='mobile_social'>
                    <img src={share} style={{'cursor':'pointer'}} alt="no share logo" onClick={() =>
                    shareOnMobile(
                        {
                        text: "Hey Check out this Biograph Memory",
                        url: shareURL,
                        title: "Biograph Memory",
                        },
                        (message) => alert(message)
                    )
                    }/>
                    <p>Share to...</p>
                </div>
                <div className='mobile_social'>
                    <img src={message} style={{'cursor':'pointer'}} alt="no message logo" onClick={shareOnMessage}/>
                    <p>Message</p>
                </div>
                <div>
                    <img src={email} style={{'cursor':'pointer'}} alt="no email logo"  onClick={shareOnEmail}/>
                    <p>Email</p>
                </div>
                <div>
                    <FacebookShareButton url={shareURL}>
                        <img src={facebook} style={{'cursor':'pointer'}} alt="no facebook logo" />
                    </FacebookShareButton>
                    <p>Facebook</p>
                </div>
                <div>
                    <TwitterShareButton url={shareURL}>
                        <img src={twitter} style={{'cursor':'pointer'}} alt="no twitter logo" />
                    </TwitterShareButton>
                    <p>Twitter</p>
                </div>

            </div>
           
        </div>


    )
}
