import React, { useState } from 'react'
// import photo from '../../assets/images/addOtherUser.png'
import meta from '../../assets/images/Metadata.png'
import addphoto from '../../assets/images/Add-Photo-Tip.png'
import cameralogo from '../../assets/images/camera-logo.png'
import qrcodeicon from '../../assets/images/QR.png'
import '../lower.css'

export default function Create({ setRecord, setMemoryImage, setMemoryImageWidth, setMemoryImageHeight }) {
    document.body.classList.add('create_body');

    const handleChange = (e) => {
        if (e.target.files.length) {
            setMemoryImage({
              preview: URL.createObjectURL(e.target.files[0]),
              raw: e.target.files[0]
            });
        }
        setRecord(true)

        console.log(URL.createObjectURL(e.target.files[0]));
        const img = new Image()
        img.src = URL.createObjectURL(e.target.files[0])
        img.onload = () => {
            // console.log(img.height);
            setMemoryImageHeight(img.height)
            setMemoryImageWidth(img.width)
            // console.log(img.width);
        };

    }

    return (
        <div className='create_div'>
            <div className='main_create_div'>
                <h2>Create new memory</h2>
                {/* <img src={meta} alt="no meta data" /> */}
                <p style={{'fontWeight':'500'}}>Activate QR <img height={15} width={15} src={qrcodeicon}/> by adding an image of the photo, object, artifact...or whatever!</p>
            </div>
            <div className='create_div_lower margin_class'>
                <div className='div1'>
                    <img src={addphoto} alt=""  className='photo2'/>
                </div>
                <div className='div2'>
                    {/* <img src={cameralogo} onClick={() => setRecord(true)} alt="" /> */}
                    <label htmlFor="upload-button">
                        <img src={cameralogo} alt="dummy" width="300" height="300" />
                    </label>
                    <input
                        type="file"
                        accept="image/*" 
                        id="upload-button"
                        style={{ display: "none" }}
                        onChange={handleChange}
                    />
                </div>
                <div className='div3'></div>

            </div>
        </div>
    )
}
