import '../sharebiograph/sharebiograph.css'
import prev from '../../assets/Rewind.png'
import next from '../../assets/Forward.png'
import play from '../../assets/Play.png'
import pause from '../../assets/Pause.png'
import userPic from '../../assets/addOtherUser.png'
import biographPic from '../../assets/biograph.png'
import mute from '../../assets/mute.imageset/mute@2x.png'
import unmute from '../../assets/unmute.imageset/unmute@2x.png'
import Header from '../../components/header/header'
import ApiInstance from '../../services/interceptor'
import { useEffect, useRef, useState } from 'react'
import { useLocation } from "react-router-dom";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import RecordSkeleton from '../../components/skeleton/recordSkeleton'
import PlayerLoader from '../../components/loader/loader'
import downloadlogo from "../../assets/Downloadlogo App.png"
import stop from "../../assets/Stop.png"
import Grouptwo from "../../assets/Grouptwo.svg"
import Addmic from '../../components/addMic/addmic'
import Stop from '../../components/stopMic/stop'
import Record from '../../components/recordOption/record'
import Sharecontact from '../../components/shareContact/sharecontact'
import Popup from '../../components/popup/popup'
import PreSignedPrivateUrl from '../../services/awspresigneurls'

export default function ShareBiograph() {


    const search = useLocation().search;
    let input_biograph_id = (search.slice(13))
    input_biograph_id = decodeURIComponent(input_biograph_id)

    const [recodsData, setRecordsData] = useState({})
    const [postData, setPostData] = useState({})
    const [loader, setLoader] = useState(true)
    const [toggleMute, setToggleMute] = useState(false)
    const [wordsCount, setWordCount] = useState(0)
    const [isVideoLoaded, setIsVideoLoaded] = useState(false)

    const audioRef = useRef()
    const [playing, setPlaying] = useState(false);
    const [currentPlaying, setCurrentPlaying] = useState('')

    const [video, setVideo] = useState('')
    const videoPlayerRef = useRef('')
    const [currentTime, setCurrentTime] = useState(false)
    const [recordmic, setRecordMic] = useState(false)
    const [stopmic, setStopMic] = useState(false)
    const [saveData, setSaveData] = useState(false);
    const [openPop, setOpenPop] = useState(false);

    const [recording, setRecording] = useState(false);
    const [audioURL, setAudioURL] = useState(null);
    const [audioBlob, setAudioBlob] = useState(null);
    const [audioContext, setAudioContext] = useState(null);
    const [audioRecorder, setAudioRecorder] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false)

    const [shareName, setShareName] = useState("")
    const [shareEmail, setShareEmail] = useState("")
    const [sharePhone, setSharePhone] = useState("")
    const [captchaSolved, setCaptchaSolved] = useState(false)
    
    const [errShareName, setErrShareName] = useState("")
    const [errShareEmail, setErrShareEmail] = useState("")
    const [errSharePhone, setErrSharePhone] = useState("")
    const [errcaptchaSolved, setErrCaptchaSolved] = useState("")

    const [saved,setSaved]=useState(false)
    const [reload, setReload] = useState(false)
    const [browserType, setBrowserType] = useState(false)

    const [loaderState, setLoaderState] = useState(false)

    useEffect(() => {
        ApiInstance.get(`v2/user/biographdetails-web/?biograph_id=${input_biograph_id}`).then((res) => {
            setPostData(res.data.responseData.Data)
            
            if (res.data.responseData.Data.video_url != '') {
                setVideo((res.data.responseData.Data.video_url))
            }

            let tempData = res.data.responseData.Data
            let newData = []

            for (let i = 0; i < tempData.length; i++){
                if (tempData[i].photourl != '') {
                    tempData[i].photourl = PreSignedPrivateUrl(tempData[i].photourl)
                    newData.push(tempData[i])
                }
                
            }
        })

        ApiInstance.get(`v2/user/biographrecords-web/?biograph_id=${input_biograph_id}`).then((res) => {
            setLoader(false)
            setRecordsData(res.data.responseData.data)

            let tempData = res.data.responseData.data
            let newData = []

            for (let i = 0; i < tempData.length; i++){
                if(tempData[i].record_url!=''){
                    setCurrentPlaying(PreSignedPrivateUrl(res.data.responseData.data[i].record_url))
                    break;
                }
            }
            
            for (let i = 0; i < tempData.length; i++){
                if (tempData[i].photourl != '') {
                    tempData[i].photourl = PreSignedPrivateUrl(tempData[i].photourl)
                }
                if (tempData[i].record_url != '') {
                    tempData[i].record_url = PreSignedPrivateUrl(tempData[i].record_url)
                }
                newData.push(tempData[i])
                
            }

            setRecordsData(newData)
        })
    }, [input_biograph_id, reload])


    useEffect(() => {
            playing ? audioRef.current.play() : audioRef.current.pause()

    }, [playing, currentPlaying])

    useEffect(() => {

        let word_count = 0

        if (recodsData.length == undefined) {
            setWordCount(word_count)
        } else {

            for (let i = 0; i < recodsData.length; i++){
                word_count = Number(recodsData[i].words_count) + Number(word_count)
            }
            setWordCount(word_count)
        }


    }, [recodsData])

    useEffect(() => {
        var sBrowser, sUsrAg = navigator.userAgent
            
        if (sUsrAg.indexOf("Firefox") > -1) {
            sBrowser = "Mozilla Firefox";

          } else if (sUsrAg.indexOf("Edge") > -1) {
            sBrowser = "Microsoft Edge";

          } else if (sUsrAg.indexOf("Chrome") > -1) {
            sBrowser = "Google Chrome or Chromium";

          } else if (sUsrAg.indexOf("Safari") > -1) {
            sBrowser = "Safari";

          } else {
            sBrowser = "unknown";
          }

        setBrowserType(sBrowser)
    },[])

    const formatDate = (date) => {
        let newdate = new Date(new Date(date).toUTCString()).toLocaleDateString('en-us', { year: "numeric", day: 'numeric', month: "short", hour: '2-digit', minute: '2-digit' })
        return newdate
    }

    const toggle = () => {
        if (video){

            var video = document.getElementById("bgVideo")
            if (video.muted == false){
            setToggleMute(!toggleMute)
            video.muted = true;
        } 
    }
        setPlaying(!playing);
    }

    const moveBack = () => {

        const index = recodsData.findIndex(x => x.record_url === currentPlaying)
        setPlaying(true)
        setCurrentTime(!currentTime)

        if (index === 0) {
            setCurrentPlaying((recodsData[recodsData.length - 1].record_url))
        } else {
            setCurrentPlaying((recodsData[index - 1].record_url))
        }

    }

    const moveNext = () => {

        const index = recodsData.findIndex(x => x.record_url === currentPlaying)
        setPlaying(true)
        setCurrentTime(!currentTime)
        if (index === recodsData.length - 1) {
            setCurrentPlaying((recodsData[0].record_url))
        } else {
            setCurrentPlaying((recodsData[index + 1].record_url))
        }

    }

    const onPlaying = () => {

        const currentTime = audioRef.current.currentTime
        const duration = audioRef.current.duration

        if(currentPlaying==''){
            console.log('current playing is now at empty');
            const index = recodsData.findIndex(x => x.record_url === currentPlaying)
            setCurrentPlaying((recodsData[index + 1].record_url))
        }

        if (currentTime === duration) {

            const index = recodsData.findIndex(x => x.record_url === currentPlaying)

            for (let i = index; i < recodsData.length; i++) {
                if (index === recodsData.length - 1) {
                    setPlaying(!playing)
                }
                else {
                    setCurrentTime(!currentTime)
                    setCurrentPlaying((recodsData[index + 1].record_url))
                }
            }
        }
    }

    const muteVideo = () => {
        setToggleMute(!toggleMute)
        var button = document.getElementById("MuteButton")
        var video = document.getElementById("bgVideo")
        if (video.muted) {
            video.muted = false;
            setPlaying(false)
        } else {
            video.muted = true;
        }

        button.classList.toggle('muted');
    }

    const recordMics = async () => {
        try{

            const result = await navigator.permissions.query({ name: 'microphone' });
            
            if (result.state == 'granted'){
                setRecordMic(true)
        }else if (result.state == 'prompt'){
            setRecordMic(false)
        } else {
            setRecordMic(false)
            if(browserType === "Google Chrome or Chromium"){
                alert('Microphone permission is not granted goto: chrome://settings/content/microphone allow and then Refresh')

            }else if (browserType === "Mozilla Firefox"){
                alert('Microphone permission is not granted goto: browser setting - Privacy & Security - Permissions - Microphone and then Refresh')
                
            }else if (browserType === "Safari"){
                alert('Microphone permission is not granted goto: safari - setting for web.biographbook.com - Microphone - allow and then Refresh')

            }else if (browserType === "Microsoft Edge"){
                alert('Microphone permission is not granted')

            }else{
                alert('Microphone permission is not granted')
            }    

        }
            } catch{
                setRecordMic(true)
            }
    }
    

    const saveDatareacord = () => {
        setSaveData(!saveData)
    }
    const onCancel = () => {
        audioElement.pause();

        setStopMic(false)
        setRecordMic(false)
    }
    const validation = ()=>{
        const phoneNumberRegex = /^\d{10}$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        let validate = true;
        if(shareName==""){
            setErrShareName("Name is required")
            validate = false
        }
        if(shareEmail==""){
            setErrShareEmail("Email is required")
            validate = false
        }else if(!emailRegex.test(shareEmail)){
            setErrShareEmail("Please enter a valid email")
            validate = false
        }
        if(sharePhone==""){
            setErrSharePhone("Phone is required")
            validate = false
        }else if(!phoneNumberRegex.test(sharePhone)){
            setErrSharePhone('Please enter a valid phone number');
            validate = false
        }
        if(captchaSolved==false){
            setErrCaptchaSolved('Please fill captcha');
            validate = false
        }
        return validate;
    }

    const onOpen = () => {

        if(validation()){
            setLoaderState(true)

            const file = new File([audioBlob], 'recorded-audio.mp3', { type: 'audio/mpeg' });
            const recordedTime = localStorage.getItem('recordedTime');
            
            let formData = new FormData();
            formData.append('biograph_id',input_biograph_id)
            formData.append('name',shareName,)
            formData.append('email',shareEmail,)
            formData.append('phone',sharePhone,)
            formData.append('record_time',recordedTime)
            formData.append('record_url',file)
            formData.append('browser_type',browserType)

            ApiInstance.post(`v2/user/web-record/`, formData).then((res) => {
                if (res.status === 200){
                    setSaveData(false);
                    setOpenPop(true);
                    setSaved(true)
                    setReload(!reload)
                    setTimeout(() => {
                        setOpenPop(false)
                        setLoaderState(false)
                        setStopMic(false)
                        setSaved(false)
                        setCaptchaSolved(false)
                        setShareName("")
                        setShareEmail("")
                        setSharePhone("")
                    setRecordMic(false)}, 1000);
                    document.body.classList.remove('body-class')

                    // bandage code for error
                    // window.location.reload();
                }
            })
        }
    }


    const startRecording = async () => {
        try {

            setPlaying(false);
            
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

            setRecordMic(true)

            const context = new AudioContext();
            const recorder = new MediaRecorder(stream);
            const chunks = [];
          
            recorder.addEventListener("dataavailable", (e) => {
                chunks.push(e.data);
            });
          
            recorder.addEventListener("stop", () => {
                const blob = new Blob(chunks, { type: "audio/mpeg" });
                const url = URL.createObjectURL(blob);
                setAudioBlob(blob);
                setAudioURL(url);
                setRecording(false);
            });
          
            recorder.start();
            setAudioContext(context);
            setAudioRecorder(recorder);
            setRecording(true);


        } catch (error) {
            console.error("Error while starting recording:", error);
        }
        
      };

    const stopRecording = () => {
        if (audioRecorder) {
          audioRecorder.stop();
          audioContext.close();
        }
        setStopMic(true)
      };

    const audioElement = new Audio(audioURL);
    const [pauseBtn, setPauseBtn] = useState(false)
      
    useEffect(() => {
            if (audioElement) {
            audioElement.addEventListener('timeupdate', () => {
                if (audioElement.currentTime == audioElement.duration){
                    setPauseBtn(true)
                    pauseRecording()
                }
            });
            }
    }, [audioElement]);

    const playRecording = () => {
        if (audioURL) {
          audioElement.play();
        }
      };

    const pauseRecording = () => {
        if (audioURL) {
          audioElement.pause();
        }
      };

    return (
        <>
            <div className='main'>

                <Header />

                <div className="lowerSection">
                    {
                        !saved?<div className="content" style={loader === true ? { 'display': 'none' } : { 'display': 'block' }}>
                        <h2 className="heading"> {postData.title || <Skeleton />} </h2>
                        {postData ? <p className="sub_heading">{formatDate(postData.created_date)}</p> : <Skeleton width={90} />}
                        {postData ? <p className="sub_heading">{postData.location} </p> : <Skeleton width={190} />}
                        {postData ? <p className="sub_heading">{recodsData.length ? recodsData.length : 0} records, {wordsCount ? wordsCount : 0} words</p> : <Skeleton width={150} />}
                        <div style={{ 'marginTop': "17px", "display": 'flex', "justifyContent": "center", "position": "relavtive"}} className='wrapper_video'>
                            {
                                postData.video_url === '' ? <div className='insideImage' > <img alt="biograph-img" src={postData.photourl.length > 0 ? PreSignedPrivateUrl(postData.photourl) : biographPic} className="userSVg" /> </div> : <video
                                    key={video} width="300" autoPlay muted id="bgVideo" playsinline="" data-wf-ignore="true" data-object-fit="cover"
                                    height="300" ref={videoPlayerRef} loop  preload="auto"> 
                                    <source
                                        src={video}
                                        preload={'auto'}
                                        type="video/mp4"
                                        data-wf-ignore="true"         
                                    />
                                </video>

                            }
                            {
                                postData.video_url !== '' && <span className='mute_btn'>
                                    {
                                        <img id="MuteButton" alt='muteBtn' src={toggleMute ? unmute : mute} className="muted" height={20} onClick={muteVideo} />
                                    }
                                </span>
                            }
                        </div>

                        <div className='playerBTN' style={loader === true ? { 'display': 'none' } : { 'display': 'flex' }}>
                            <img src={prev} alt='backBtn' onClick={moveBack} style={{ 'marginRight': '48px' }} height={30} />
                            <audio src={(currentPlaying)} ref={audioRef} onTimeUpdate={onPlaying} onEmptied={() => { setCurrentTime(!currentTime) }} />

                            {
                                // currentTime === false  ? <PlayerLoader /> : 
                                <img onClick={toggle} alt='play/pause' src={playing ? pause : play} height={30} className="playerOne_btn" />
                            }

                            <img src={next} alt='nextBtn' onClick={moveNext} style={{ 'marginLeft': '48px' }} height={30} />
                        </div>

                        <div className="wrapper">
                            {
                                recodsData.length > 0 ? recodsData.map((i, index) => {

                                    return (
                                        <div className="sub_content" id={index} key={index} >
                                            <div className="left">
                                                <img alt="biograph-img" src={recodsData[index].photourl.length > 0 ? recodsData[index].photourl : userPic} className="sub_user" height={40} width={40} />
                                            </div>
                                            <div className="right">
                                                <h6 className="subhead">
                                                    {recodsData[index].username=="Web User" ? recodsData[index].name : (recodsData[index].username ? recodsData[index].username :recodsData[index].name) } <span className="details">{formatDate(recodsData[index].created_date)}</span>
                                                </h6>
                                                <p className="sub_description">
                                                    {recodsData[index].record_text}
                                                </p>
                                            </div>
                                        </div>
                                    )
                                }) : '' 
                                // <RecordSkeleton />
                            }
                        </div>
     
                        <div className="button">
                            {
                                loader === true ? <Skeleton height={50} /> :
                                    <div className='bio_div' >
                                        {!recordmic ? <Addmic recodmic={recordMics} startRecording={startRecording}/> : ''}
                                        {(recordmic && !stopmic) ? <Stop stopmic={stopRecording} stopRecording={stopRecording} /> : ''}

                                        {stopmic ? <Record saveData={saveDatareacord} playRecording={playRecording} pauseBtn={pauseBtn} setPauseBtn={setPauseBtn}
                                                         pauseRecording={pauseRecording} onCancel={onCancel} /> : ''}

                                        {saveData ? <Sharecontact saveData={saveDatareacord} onOpen={onOpen} loaderState={loaderState}
                                                                shareName={shareName} setShareName={setShareName} 
                                                                setShareEmail={setShareEmail} setSharePhone={setSharePhone} 
                                                                errShareName={errShareName} setErrShareName={setErrShareName}
                                                                errShareEmail={errShareEmail} setErrShareEmail={setErrShareEmail}
                                                                errSharePhone={errSharePhone} setErrSharePhone={setErrSharePhone}
                                                                setCaptchaSolved={setCaptchaSolved} setErrCaptchaSolved={setErrCaptchaSolved}
                                                                errcaptchaSolved={errcaptchaSolved} /> : ''}
                                    </div >
                            }
                            
                        </div>
                        
                        </div>:<div className='pop_div' > {openPop ? <Popup /> : ""} </div >
                    }
                    
                </div>
            </div>
        </>
    )
}
