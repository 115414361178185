import React, { useState } from 'react'
import photo1 from '../../assets/images/Grouptwo.svg'
import photo2 from '../../assets/images/Stop.png'

export default function Record_main({ setRecordsub, startMemoryRecording }) {
      
    // const click=()=>{
    //     console.log('starting.............');
    //     setRecordsub(true)
    //     startMemoryRecording()
    // }

    return (
        <>
            <div className='div1'>
                <p className='add_audio_content' style={{'fontSize':"14px", 'fontWeight': '600', 'lineHeight': '18px'}}>Add audio <div>message!</div></p>
            </div>
            <div className='div2'>
                <img src={photo2} onClick={() => startMemoryRecording()} alt="no mic button" />
            </div>
            <div className='div3'></div>
        </>
    )
}
