import React, { useState, useEffect }  from 'react'
import stopMic from "../../assets/StopMic.png"
import GroupThree from "../../assets/Groupthree.png"
import '../stopMic/stop.css'
import WaveSurfer from 'wavesurfer.js';
import MicrophonePlugin from 'wavesurfer.js/src/plugin/microphone';



export default function Stop({stopmic}, {stopRecording}) {

    const [time, setTime] = useState(0);

    useEffect(() => {

      const wavesurfer = WaveSurfer.create({
        container: '#waveform',
        waveColor: 'red',
        barMinHeight: 4,
        // progressColor: 'purple',
        cursorColor: "transparent",
        hideCursor:true,
        cursorWidth: 10,
        barWidth: 6,
        barRadius: 5,
        maxCanvasWidth:1000,
        audioRate:1,
        responsive: true,
        barHeight:2,
        height:50,
        width:2,
        plugins: [MicrophonePlugin.create()]
      });
      wavesurfer.microphone.on('deviceReady', function(stream) {
        // console.log('Device ready!', stream);
      });
      wavesurfer.microphone.start();
    },[])


    useEffect(() => {
        const interval = setInterval(() => {
          setTime(prevTime => prevTime + 1);
        }, 1000); // Update time every 1 second`
    
        // record only 60 second audio
        if (time > 60){
          stopmic()
        }

        return () => {
          clearInterval(interval);
        };
      }, [time]);
    
      const formatTime = (seconds) => {
          
          const minutes = Math.floor((seconds % 3600) / 60);
          const remainingSeconds = seconds % 60;
          
          return `${minutes < 10 ?  + minutes : minutes}:${remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds} / 1:00`;
        };

    // setting time state in locaclstorage
      localStorage.setItem('recordedTime', time);

      

    return (
        <div className='add_main_stop'>
            <div className='add_audio_stop'>
                <div style={{width:'20%'}} id="waveform" />
                <img src={stopMic} alt="add" 
                    className='add_img stop_image' onClick={stopmic}></img>
                <div><h2>{formatTime(time)}</h2></div>
            </div>
        </div>
    )
}
