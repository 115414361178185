import React, { useState } from 'react'
import Record_main from './Record_main';
import Record_sub from './Record_sub';
import Record_play from './Record_play';
import '../lower.css'
// import MicRecorder from 'mic-recorder-to-mp3';


export default function Record({ setAddDetails,playpause, setMemoryAudio, audioBlob, setAudioBlob}) {
    document.body.classList.remove('create_body');
    document.body.classList.add('record_body');
    document.body.classList.remove('add_detail_body');

    const [recordsub, setRecordsub] = useState(false);
    const [recordplay, setRecordplay] = useState(playpause);
    // const Mp3Recorder = new MicRecorder({ bitRate: 128 });
    // const [blobURL, setBlobUrl] = useState("")
    const [isRecording, setIsRecording] = useState(false)
    const [audioContext, setAudioContext] = useState(null);
    const [audioRecorder, setAudioRecorder] = useState(null);
    const [audioURL, setAudioURL] = useState(null);
    // const [audioBlob, setAudioBlob] = useState(null);

    const openRecordSub = () => {
        setRecordplay(false);
        setRecordsub(false);
    }

    
    const startMemoryRecording = async () => {
        try {            
            console.log('started recording...............');
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            // const result = await navigator.permissions.query({ name: 'microphone' });
            // console.log(result.state,'----------------------------permission status');

            // if (result.state == 'granted'){
                setIsRecording(true)
                setRecordsub(true)

                const context = new AudioContext();
                const recorder = new MediaRecorder(stream);
                const chunks = [];
          
                recorder.addEventListener("dataavailable", (e) => {
                  chunks.push(e.data);
                });
          
                recorder.addEventListener("stop", () => {
                  const blob = new Blob(chunks, { type: "audio/mpeg" });
                  const url = URL.createObjectURL(blob);
      
                // console.log(url,'--audio');
                  setAudioBlob(blob);
                  setAudioURL(url);
                //   setRecording(false);

                const file = new File([audioBlob], 'recorded-audio.mp3', { type: 'audio/mpeg' });
                setMemoryAudio(file)
                console.log(111111111111);
                });
          
                recorder.start();
                setAudioContext(context);
                setAudioRecorder(recorder);

            // } else {
            //     console.log('Permission not granted');
            // }

        } catch (error) {
            console.error("Error while starting recording:", error);
        }
        
      };

    const stopMemoryRecording = () => {
        setIsRecording(true)
        if (audioRecorder) {
          audioRecorder.stop();
          audioContext.close();
        }
        setRecordplay(true)
      };

      const audioElement = new Audio(audioURL);
      const [pauseBtn, setPauseBtn] = useState(false)
      

      const playRecording = () => {
        if (audioURL) {
          audioElement.play();
        }
      };

    const pauseRecording = () => {
        if (audioURL) {
          audioElement.pause();
        }
      };


    return (
        <div className='create_div'>
            <div className='main_create_div'>
                <h2>Record a story</h2>
                <p>A picture says a thousands words! Share some details by adding a voice recording.</p>
                <h6 style={{"cursor":'pointer'}} onClick={()=>{setAddDetails(true)}}>skip and add later</h6>
            </div>
            <div className='create_div_lower margin_class'>
                {!recordplay && !recordsub && <Record_main setRecordsub={setRecordsub} startMemoryRecording={startMemoryRecording} />}
                {!recordplay && recordsub && <Record_sub setRecordplay={setRecordplay} stopMemoryRecording={stopMemoryRecording} />}
                {recordplay && <Record_play setAddDetails={setAddDetails} setRecordsub={openRecordSub} playRecording={playRecording} pauseRecording={pauseRecording} recordsub={recordsub} audioBlob={audioBlob}/>}
            </div>
        </div>
    )
}
