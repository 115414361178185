import React from 'react'
import saveSvg from "../../assets/Save.png"
import Cancel from "../../assets/Cancel.png"
import '../shareContact/sharecontact.css'
import { Modal } from 'antd'
import { useState } from 'react'
import ReCAPTCHA from "react-google-recaptcha";
import PlayerLoader from '../loader/loader'


export default function Sharecontact({ saveData, shareName, onOpen, setShareName, setShareEmail, setSharePhone, loaderState,
                                    setErrShareName, setErrShareEmail, setErrSharePhone, setErrCaptchaSolved,captchaSolved,
                                    errShareName, errShareEmail, errSharePhone, setCaptchaSolved,errcaptchaSolved}) {

    const [isModalOpen, setIsModalOpen] = useState(true)

    const handleState = () => {
         
        setIsModalOpen(false);
        saveData()
        document.body.classList.remove('body-class')
    }

    const onChange = (value) => {
        setCaptchaSolved(true)
        setErrCaptchaSolved("")
    }

    return (
        <Modal
            title="Basic Modal"
            open={isModalOpen}
            className="contact_modal">
            <h2 className='share_heading'>Share your contact</h2>

            <div className='input_main_div'>
                <div className='input_div'>
                <input type="name" placeholder='name (will appear on recording)' onChange={e => (setShareName(e.target.value), setErrShareName(""))} className='input_box'></input>
                <div className='error'>{errShareName?errShareName:""}</div>
                </div>
                <div className='input_div'>
                <input type="email" placeholder='email' onChange={e => (setShareEmail(e.target.value), setErrShareEmail(""))} className='input_box'></input>
                <div className='error'>{errShareEmail?errShareEmail:""}</div>
                </div>
                <div className='input_div'>
                <input type="number" placeholder='phone number' onChange={e => (setSharePhone(e.target.value), setErrSharePhone(""))} className='input_box'></input>
                <div className='error'>{errSharePhone?errSharePhone:""}</div>
                </div>
                <div className='input_div'>
                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                    onChange={onChange}
                />
                <div className='error'>{errcaptchaSolved?errcaptchaSolved:""}</div>
                </div>

            </div>
            <div className='share_img'>
            { loaderState ? <PlayerLoader /> : <>
                <img src={Cancel} alt="Cancel" className='share_cancel_img' onClick={() => {
                    handleState()
                }}></img>
                <img src={saveSvg} alt="save"  className='share_save_img' onClick={() => {onOpen();}}></img>
            </>}
            </div>
        </Modal>
    )
}
