import React from 'react'
import memory from '../../assets/images/memory_saved.png'

export default function index() {
    return (
        <div>
            <div className='popup'>
                <div className='main_create_div'>
                    <img src={memory} alt="no memory saved" />
                </div>
            </div>
        </div>
    )
}
