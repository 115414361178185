import React from 'react'
import photo from '../../assets/images/downnloadlogo.png'
import DownloadApp from '../../assets/images/Download-App.svg'
import '../lower.css'

export default function Share() {
    return (
        <div className='create_div share_main_div'>
            <div className='main_create_div'>
                <img src={photo} alt="no download logo" />
                <h2>Download app to see all your biograph in your place</h2>
                <button className='download_app_btn'><img src={DownloadApp} alt="" /></button>
            </div>
           
        </div>
    )
}
