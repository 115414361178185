import React from 'react'
import Popup_svg from "../../assets/Pop-up.png"
import "../popup/popup.css"


export default function Popup() {
    return (
        <div className='pop_main'>
 <div className='main_popup'>
            <img src={Popup_svg} alt='popup' className='popup_Image'></img>
        </div>
        </div>
       
    )
}
