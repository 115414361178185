import React, { useEffect, useState } from 'react'
import stopmic from '../../assets/images/StopMic.png'
import record from '../../assets/images/Groupthree.png'
import WaveSurfer from 'wavesurfer.js';
import MicrophonePlugin from 'wavesurfer.js/src/plugin/microphone';

export default function Record_main({ setRecordplay, stopMemoryRecording }) {
    
    const [time, setTime] = useState(0);

    useEffect(() => {

        const wavesurfer = WaveSurfer.create({
          container: '#waveform',
          waveColor: 'red',
          barMinHeight: 4,
          // progressColor: 'purple',
          cursorColor: "transparent",
          hideCursor:true,
          cursorWidth: 10,
          barWidth: 6,
          barRadius: 5,
          maxCanvasWidth:1000,
          audioRate:1,
          responsive: true,
          barHeight:2,
          height:50,
          width:2,
          plugins: [MicrophonePlugin.create()]
        });
        wavesurfer.microphone.on('deviceReady', function(stream) {
          // console.log('Device ready!', stream);
        });
        wavesurfer.microphone.start();
      },[])

      useEffect(() => {
        const interval = setInterval(() => {
          setTime(prevTime => prevTime + 1);
        }, 1000); // Update time every 1 second`
    
        // record only 60 second audio
        if (time > 60){
          // stopmic()
          stopMemoryRecording()
        }

        return () => {
          clearInterval(interval);
        };
      }, [time]);
    
      const formatTime = (seconds) => {
          
          const minutes = Math.floor((seconds % 3600) / 60);
          const remainingSeconds = seconds % 60;
          
          return `${minutes < 10 ?  + minutes : minutes}:${remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds} / 1:00`;
        };

    // setting time state in locaclstorage
      localStorage.setItem('memoryRecordedTime', time);

    return (
        <>
            <div className='div1'>
                {/* <img src={record} alt="no record line" className='photo1' /> */}
                <div style={{width:'70%'}} id="waveform" />                
            </div>
            <div className='div2'>
                <img src={stopmic} onClick={()=> stopMemoryRecording()} alt="no stop mic" />
            </div>
            {/* <div className='div3'></div> */}
            <div><h3>{formatTime(time)}</h3></div>
        </>
    )
}
