import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ShareBiograph from './pages/sharebiograph/sharebiograph';
import PageNotFound from './components/PageNotFound';
import Sharecontact from './components/shareContact/sharecontact';
import Popup from './components/popup/popup';
import GenerateNullQR from './components/generateNullQR/other';
import ActivateQR from './components/Main';
import CheckNullStatus from './components/nullStatus/nullStatus';

function App() {
  return (

    <BrowserRouter>
    
      <Routes>
        <Route path="/linkedbiographs/" element={ <ShareBiograph /> }/>
        <Route path="/check_null_status/" element={ <CheckNullStatus /> }/>
        <Route path="/generate_null_qr/" element={ <GenerateNullQR /> }/>
        <Route path="/create_memory/" element={ <ActivateQR /> }/>
        <Route path="*" element={ < PageNotFound /> }/> 
        <Route path="/shareContact" element={ <Sharecontact /> }/>
        <Route path="/popup" element={ <Popup /> }/>
      </Routes>

    </BrowserRouter>

  )}

export default App;
