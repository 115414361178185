import React, { useState } from 'react'
import './index.css'
import ApiInstance from '../../services/interceptor'
import Header from '../header/header'
import {Helmet} from "react-helmet";
import {useDropzone} from 'react-dropzone';
import PlayerLoader from '../loader/loader';


export default function GenerateNullQR() {

  const [noOfQR, setNoOfQR] = useState("")
  const [qrFillColor, setQrFillColor] = useState("")
  const [qrBackColor, setQrBackColor] = useState("")
  const [loaderState, setLoaderState] = useState(false)
  const [errNoOfQR, setErrNoOfQR] = useState("")
  const [errQrFillColor, setErrQrFillColor] = useState("")
  const [errQrBackColor, setErrQrBackColor] = useState("")
  const [errQrImage, setErrQrImage] = useState("")
  const [passwordStatus, setPasswordStatus] = useState(false)
  const [inputPassword, setInputPassword] = useState("")
  const [errInputPassword, setErrInputPassword] = useState("")
  
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({accept: {'image/jpeg': ['.jpeg']}})

  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} 
    </li>
  ));

  // - {file.size} bytes

  const validation = ()=>{
    const hexCodeRegex = (/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/)
    let validate = true;

    if(noOfQR==""){
      setErrNoOfQR("No of QRs is required")
        validate = false
    }

    if(qrFillColor==""){
        setErrQrFillColor("Hex Code is required")
        validate = false
    }else if(!qrFillColor.match(hexCodeRegex)){
      setErrQrFillColor('Please enter a valid Hex code');
        validate = false
    }

    if(qrBackColor==""){
      setErrQrBackColor("Hex Code is required")
      validate = false
    }else if(!qrBackColor.match(hexCodeRegex)){
      setErrQrBackColor('Please enter a valid Hex code');
        validate = false
    }

    if(acceptedFiles[0]==undefined){
      setErrQrImage('Image is required');
      validate = false 
    }

    return validate;
  }

  const verifyuser = () => {
    // console.log(1234567890, inputPassword)
    if(inputPassword==="SafeKeepQR98!"){
      setPasswordStatus(true)
    }else{
      setErrInputPassword("Incorrect Password!")
    }
  }

  const generateQR = () => {

    if(validation()){
      
      setLoaderState(true)

      let formData = new FormData();
      formData.append('no_of_null_bio',noOfQR)
      formData.append('fill_color_of_null_bio',qrFillColor)
      formData.append('back_color_of_null_bio',qrBackColor)
      formData.append('img_of_null_bio',acceptedFiles[0])

      const axiosOptions = {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json'
        }
      }
      
      ApiInstance.post(`v1/admin/generate-null-biographs/`, formData, axiosOptions).then((res) => {
        if (res.status === 200){
          console.log((res));
          const blob = new Blob([res.data], {
            type: 'application/octet-stream'
          })
          const element = document.createElement("a");
          element.href = URL.createObjectURL(blob);
          element.download = "BlankQR.zip"
          document.body.appendChild(element);
          element.click();
          setLoaderState(false)
        }})
    }
      
  }

  return (
    <div>
      <Header />
      <Helmet>
        <title>Generate Null Biographs</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      {
        passwordStatus ? 
        <div className='bio_container'>
          <div className='other_main'>
            <h1>Biograph blank QR generator</h1>
            <div className='input_div'>
              <input type="number" onChange={e => (setNoOfQR((e.target.value).trim()),setErrNoOfQR(""))} placeholder='how many QR codes?' />
              <div className='error'>{errNoOfQR?errNoOfQR:""}</div>
              <input type="text" onChange={e => (setQrFillColor((e.target.value).trim()), setErrQrFillColor(""))} placeholder='fill color (hex code,#000000 default)' />
              <div className='error'>{errQrFillColor?errQrFillColor:""}</div>
              <input type="text" onChange={e => setQrBackColor(((e.target.value).trim()), setErrQrBackColor(""))} placeholder='back color (hex code,#000000 default)' />
              <div className='error'>{errQrBackColor?errQrBackColor:""}</div>
              
              <section className="container">
                <div {...getRootProps({className: 'dropzone'})}>
                  <input  {...getInputProps()}  />
                  <p>Click to upload logo or Drag here</p>
                </div>
                <aside>

                  <ul class = 'image-field'>{files}</ul>
                </aside>
              </section>
              <div className='error'>{errQrImage?errQrImage:""}</div>
            </div>
            <div className='generate_btn_div' >
            {loaderState ? <PlayerLoader /> : <button className='generate_btn' onClick={generateQR}>Generate</button> }
  
            </div>
          </div>
        </div> : <div className='bio_container'>
            <div className='other_main'>
              <h1>Biograph blank QR generator</h1>
              <div className='input_div'>
                <input type="text" onChange={e => (setInputPassword((e.target.value).trim()),setErrInputPassword(""))} placeholder='Enter password to continue' />
                <div className='error'>{errInputPassword?errInputPassword:""}</div>
              </div>
              <div className='generate_btn_div' >
                <button className='generate_btn' onClick={verifyuser}>Proceed</button>
              </div>
            </div>
        </div>
      }
    </div>
  )
}
