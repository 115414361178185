import React, { useEffect } from 'react'
import recordSvg from "../../assets/Record Options.png"
import cancel from "../../assets/Cancel.png"
import Play from "../../assets/Play-Pause.png"
import pause from "../../assets/Play-Pause1.png"
import saveSVg from "../../assets/Save.png"

import "../recordOption/record.css"
import Sharecontact from '../shareContact/sharecontact'
import { useState } from 'react'


export default function Record({ saveData, onCancel, playRecording, pauseRecording, pauseBtn, setPauseBtn }) {

    // const [modalIsOpen, setModalIsOpen] = useState(false);
    // const setModalIsOpenToTrue = () => {
    //     setModalIsOpen(true)
    // }
    const [Playopen, setPlayopen] = useState(true);
    useEffect(() => {
        if (pauseBtn){
            onClick()
            setPauseBtn(false)
        }

    },[pauseBtn])
    function onClick() {
        // console.log(Playopen);
        setPlayopen(!Playopen);
        // console.log(Playopen);
    }

    const hideHeader = () => {
        document.body.classList.add('body-class')
    }
    
//     return (
//         <div className='add_main_stop'>
//             <div className='add_audio_stop'>
//                 <div className='record_svg'>
//                     <img src={cancel} alt="cancel" onClick={() => onCancel()} />
//                     <p>
//                         {
//                             Playopen? <img src={Play} onClick={() => {onClick(); playRecording();}} alt="play"></img> : <img src={pause} alt="pause" onClick={()=>{onClick(false);  pauseRecording();}} />
//                         }
//                     </p>
//                     <img src={saveSVg} alt="save" onClick={()=>(saveData(), hideHeader())}></img>
//                 </div>
//             </div>
//         </div>
//     )
// }
    return (
    <div className='add_main_stop'>
        <div className='add_audio_stop record_div'>
            <div className='record_svg'>
                <img src={cancel} alt="cancel" onClick={() => onCancel()}  className="cancel_btn"/>
                <p>
                    {
                        Playopen? <img src={Play} onClick={() => {onClick(); playRecording();}} alt="play" className='play_pause'></img> : <img src={pause} alt="pause" className='play_pause' onClick={()=>{onClick(false);  pauseRecording();}} />
                    }
                </p>
                <img src={saveSVg} alt="save" className="cancel_btn" onClick={()=>(saveData(), hideHeader())}></img>
            </div>
        </div>
    </div>
)
}
